import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Row } from "react-bootstrap";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle
} from "reactstrap";

// import Card from "../../../../components/Card/Card.jsx";
import Pagination from "../../../shared/Pagination/Pagination.jsx"
import i18n from "../../../../i18n";
import { renderTranslation } from "services/renderTranslation.service.js";
class CustomerAppointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      meta: null
    };
  }

  componentDidMount() {
    if (
      this.props.appointments &&
      Object.keys(this.props.appointments).length !== 0
    )
      this.normalizeCustomerAppointmentDetail(this.props.appointments);
  }

  normalizeCustomerAppointmentDetail(appointments) {
    if (appointments && appointments.data && appointments.data.length > 0) {
      const {data , meta} = appointments
      this.setState({data, meta});
    }
  }

  handlePaginate = (direction) => {
    let page = 0;
    if(direction === 'next') {
      page = this.state.meta.current_page + 1;
    } else if( direction === 'prev' ) {
      page = this.state.meta.current_page - 1;
    }
    this.props.paginate(page);
  }

  render() {
    const { store } = this.props;
    return (
      <div className="content customer-appointments">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">{renderTranslation(store, "profile.customers_profile_appointments_tab_title")}</CardTitle>
          </CardHeader>
            <CardBody>
              <Row>
            <Table responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th> {renderTranslation(store, "profile.customers_profile_appointments.table_header.date")}</th>
                  <th> {renderTranslation(store, "profile.customers_profile_appointments.table_header.type")}</th>
                  <th> {renderTranslation(store, "profile.customers_profile_appointments.table_header.rate")}</th>
                  <th>{renderTranslation(store, "profile.customers_profile_appointments.table_header.task")}</th>
                </tr>

              </thead>
              {this.state.data.length > 0 && (
                <tbody>
                  {this.state.data.map((prop, key) => {
                    return (
                      <tr>
                        <td>{prop.id}</td>
                        <td>{prop.created_at}</td>
                        <td>{prop.type}</td>
                        <td>{prop.rate}</td>
                        <td>{prop.task.singular}</td>

                      </tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
          </Row>
        </CardBody>
        <CardFooter>
        {this.state.meta && <Pagination meta={this.state.meta} onPaginate={this.handlePaginate} />}
        </CardFooter>
      </Card>
      </div>
    );

  }
}
const mapStateToProps = (state) => {
  return {
    appointments: state.customer.appointments,
    store:state
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAppointments);
