export const baseEndpoint = {
    partnersServiceBaseEndPoint: process.env.REACT_APP_GATEWAY,
    consumerServiceBaseEndPoint: process.env.REACT_APP_API,
    mediaServiceBaseEndPoint: process.env.REACT_APP_MEDIA,
    translationServiceBaseEndpoint: process.env.REACT_APP_GATEWAY
}
export const mediaBaseUrl = '/assets/';

const v1 = "api/v1";
const v1Partner = "api/v1/partners";
const csV1 = "api/cs/v1";

export const apiUrls = {
    auth: {
        forgotPassword: `${v1}/forget-password`,
        resendEmailToken: `${v1}/verify/resend_email_token`,
        verifyEmail: `${v1}/verify/email`,
        resetPassword: `${v1}/reset-password`,
        login: `${v1}/login`,
        profile: `${v1}/auth/profile`,
        profileDetail: (uuid) => `${v1Partner}/profile/${uuid}`,
        refreshToken: `${v1}/token/refresh`,
        getAppSettings: `${v1}/system/settings`,
        getCountries: `countries?name_contains=`
    },
    feed: {
        feed: `${v1}/partners/appointments`,
        subuserFeed: `${v1}/partners/users/appointments/filter/pending`
    },
    createAppointment: {
        tasks: '/tasks-bare-minimum',
        taskDetails: data => `/api/partner/v1/tasks/${data}/dynamic_fields`,
        partner: `${csV1}/partners?per_page=1001`,
        create: `${v1Partner}/appointments`,
        subuserCreate: `${v1Partner}/users/appointments`,
        taskRates: (data) => { return `task-rates/${data.task_id}/locations/${data.lat},${data.lng}?from=${data.chosenDate}T${data.hour}:59:59Z`},
        verifyCoupon: (code) => { return `${v1}/marketing/coupon-codes/${code}/apply`},
        uploadImage: `${v1}/media/upload`,
        parkedJob: (id, type) => { return `${v1Partner}/users/appointments/action/${id}/${type}`}
    },
    appointments : {
        comments : {
            create: `${v1Partner}/comments/store`,
            fetch: (appointmentId) => `${v1Partner}/comments?appointment=${appointmentId}`
        },
        professionals: {
            all : (appointmentId) => `match/appointments/${appointmentId}/professionals`
        },
        cancel_reason: `${v1}/reasons/cancel`,
        complaints: `${v1Partner}/complaints/filter`,
        cancellation: 'cancel/appointment'
    },
    profile: {
        updatePartner: (partnerId) => {return `${v1Partner}/profile/${partnerId}`},
        fetchAllConsumer: (params = '') => { return `/${v1Partner}/customers/${params}`},
        fetchConsumerList: () => { return `/${v1Partner}/customers/all`},
        updateCustomer: (id) => { return `${v1Partner}/customers/${id}/profile` },
        fetchSingleCustomer: (id) => { return `${v1Partner}/customers/${id}/profile` },
        fetchCustomerAddress: `${v1}/addresses/filter`,
        verifyCustomerAddress: `${csV1}/verify/address`,
        updateCustomerAddress: (id) => { return `${v1Partner}/customers/${id}/address`},
        fetchCustomerAppointments: (params= "") => {return `${csV1}/appointments/filter${params}`}
    },

    dashboard: {
        getPartnerStats: (params = '') => {return `${v1}/partners/stats/charts?${params}`},
    },

    categories: {
        fetchAllCategories: (params = '', partnerId) => { return `${v1Partner}/task/${partnerId}/${params}`},
    },

    media: {
        get: `${v1}/media/filter`,
        upload: `${v1}/media/upload`,
        filter: `${v1}/media/filter`,
        delete: (mediaPath) => `${v1}/media/${mediaPath}`,
    },
    customer: {
        create: `${v1Partner}/customer-profile`
    },
    verify: {
        verifyAddress: `${csV1}/verify/address`
    },
    partnerContacts: {
        create: `${v1Partner}/profile/contacts`,
        update: (uuid) => { return `${v1Partner}/profile/contacts/${uuid}`},
        destroy: (uuid) => { return `${v1Partner}/profile/contacts/${uuid}`},
        fetchAll: `${v1Partner}/profile/contacts`,
        fetchSingle: (uuid) => { return `${v1Partner}/profile/contacts/single/${uuid}`},
    },
    translations: {
        fetchLocales: '/i18n/locales',
        fetchTranslations: '/translations'
    },
    subUser: {
        fetchAllSubUsers: (params = '') => { return `${v1}/partners/user/list`},
        createSubUser: `${v1}/partner/user/register`
    }
}

